<template>
  <div class="home">
    <Banner />
    <Fantasy />
    <Figure />
    <VisualFeast />
    <DoujinStand v-if="false" />
    <div class="footer">
      <Footer />
      <Fool />
    </div>
  </div>
</template>

<script>
import Fantasy from './models/Fantasy'
import Figure from './models/Figure'
import VisualFeast from './models/VisualFeast'
import DoujinStand from './models/DoujinStand'
import Banner from './models/Banner'
import Footer from './models/Footer'
import Fool from '@/components/Floor'

export default {
  name: 'Home',
  components: { Fantasy, Figure, VisualFeast, DoujinStand, Banner, Footer, Fool },
  data() {
    return {

    }
  },
  methods: {
    handleClick() {
    }
  }

}
</script>

<style lang="scss" scoped>
.home {

}
</style>
